import React from 'react';
import Head from 'next/head';
import Script from 'next/script';
import type { AppProps } from 'next/app';
import GlobalStyles from '../styles/global';
import { MiddlewareProviders } from '../providers';

function MyApp({ Component, pageProps }: AppProps) {

   

  return (
    <>
      {process.env.NEXT_PUBLIC_LAYER === 'prod' && (
        <>
          <Script
            strategy="lazyOnload"
            src={`https://www.googletagmanager.com/gtag/js?id=G-KKJ0JENHL1`}
          />
          <Script strategy="lazyOnload">
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-KKJ0JENHL1', {
                page_path: window.location.pathname,
                });
            `}
          </Script>
        </>
      )}
      <Head>
        <title>DGE - Desafios da Gestão Estadual</title>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="shortcut icon" href="logo.png" type="image/x-icon" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:wght@400;500;700&display=swap" rel="stylesheet" />

        <meta property="og:description" content="Desafios dos estados 2022" />

        <meta property="og:image" content="share.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="600" />
      </Head>
      <MiddlewareProviders>
        <GlobalStyles />
        <Component {...pageProps} />
      </MiddlewareProviders>
    </>
  );
}

export default MyApp;

import styled from 'styled-components';
import Indicator_01 from '../../assets/area/life_conditions/indicator_01.svg';
import Indicator_02 from '../../assets/area/life_conditions/indicator_02.svg';
import Indicator_03 from '../../assets/area/life_conditions/indicator_03.svg';

export const InadequacyIcon = styled(Indicator_01)`
  scale: 80%
`;
export const SanitationIcon = styled(Indicator_02)`
  scale:80%
`;

export const C02EmissionIcon = styled(Indicator_03)`
  scale:80%
`;

import { AxiosPromise } from 'axios';
import { axiosDataInstance } from '../axios';

export interface RankingServiceProps {
  [key: string]: any
}
interface ConfigProps {
  accessToken: string;
}
export class RankingService {
  getPetalRanking = ({ accessToken }: ConfigProps): AxiosPromise<RankingServiceProps> =>
  axiosDataInstance.get('/petal-ranking/', {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
}

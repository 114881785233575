import _ from 'lodash';
import React from 'react';
import Cookies from 'js-cookie';
import { useAuth } from '@/hooks';
import { IndicatorsService } from '@/service';
import { ACCESS } from '@/utils/accessVariables';
import { IVariacoes, MuiIndicatorProps } from '@/mock/indicators';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

interface IndicatorContextProps {
  indicators: any;
  areaCommitedItem: string;
  indicatorCommitedItem: string;
  indicatorIndex: number | null;
  indicatorsToCards: IVariacoes[];
  indicatorsData: Record<string, any>;
  handleCommitedArea: (e: any) => void;
  setAreaCommitedItem: SetState<string>;
  indicatorItem: IVariacoes | undefined;
  handleCommitedIndicator: (e: any) => void;
  setIndicatorIndex: SetState<number | null>;
  setIndicatorsToCards: SetState<IVariacoes[]>;
  setIndicatorItem: SetState<IVariacoes | undefined>;
}

export const IndicatorContext = React.createContext({} as IndicatorContextProps);

interface IProps {
  children: React.ReactNode
}

export const IndicatorContextProvider: React.FC<IProps> = ({ children }) => {
  const { existAccessToken } = useAuth();
  const { getIndicators } = new IndicatorsService();
  const [indicators, setIndicators] = React.useState({} as any);
  const [areaCommitedItem, setAreaCommitedItem] = React.useState<string>('');
  const [indicatorIndex, setIndicatorIndex] = React.useState<number | null>(null);
  const [indicatorsToCards, setIndicatorsToCards] = React.useState<IVariacoes[]>([]);
  const [indicatorCommitedItem, setIndicatorCommitedItem] = React.useState<string>('');
  const [indicatorItem, setIndicatorItem] = React.useState({} as IVariacoes | undefined);
  
  const fetchingIndicators = React.useCallback(() => {
    const accessToken = Cookies.get(ACCESS.JWT_TOKEN) as string;
    getIndicators({ accessToken }).then(({ data }) => setIndicators(data));
  }, []);

  React.useEffect(() => {
    fetchingIndicators();
  }, [existAccessToken]);

  const indicatorsData = React.useMemo(() => indicators[areaCommitedItem], [areaCommitedItem]);

  React.useEffect(() => {
    const copyRequest = indicatorsData as IVariacoes[];
    const length = copyRequest?.length;
    const arrayMuiProps = Array.from({ length }, () => MuiIndicatorProps[areaCommitedItem]);
    const mergeRequestWithMuiProps = _.merge(copyRequest, arrayMuiProps);
    const verifyLen = Object.entries(mergeRequestWithMuiProps).map(item => item).length;
    if (verifyLen > 0 && !indicatorItem) {
      const selectedIndicatorItem = mergeRequestWithMuiProps.find(item => item.code === indicatorCommitedItem);
      setIndicatorItem(selectedIndicatorItem);
    }
  }, [areaCommitedItem, indicatorCommitedItem, indicatorsToCards]);

  const handleCommitedArea = (e: any) => {
    setIndicatorItem(undefined);
    setIndicatorCommitedItem('');
    setAreaCommitedItem(e);
  };

  const handleCommitedIndicator = (e: any) => {
    setIndicatorCommitedItem(e);
    const arrayCopy = [...indicatorsData];
    arrayCopy.forEach(item => item.clicked = false);
    const changeObject = _.flatMap(arrayCopy, (item, index) => {
      if (item.code === e) {
        setIndicatorIndex(index);
        const value = { ...item, clicked: true };
        setIndicatorItem(value);

        return {
          ...item,
          clicked: true,
        };
      }
      return {
        ...item,
        clicked: false
      };
    });
    setIndicatorsToCards(changeObject);
  };


  const value = {
    indicators,
    indicatorItem,
    indicatorIndex,
    indicatorsData,
    setIndicatorItem,
    areaCommitedItem,
    setIndicatorIndex,
    indicatorsToCards,
    handleCommitedArea,
    setAreaCommitedItem,
    setIndicatorsToCards,
    indicatorCommitedItem,
    handleCommitedIndicator,
  } as IndicatorContextProps;
  return <IndicatorContext.Provider value={value}>{children}</IndicatorContext.Provider>;
};

import _ from 'lodash';
import React from 'react';
import Router, { useRouter } from 'next/router';

interface NavigatorContextProps {
  labels: IDataProps[];
  toggleActive: (index: number) => void;
  handleChangePathname: (path: string) => void;
  toggleActiveStyles: (index: number) => boolean;
}

export const NavigatorContext = React.createContext({} as NavigatorContextProps);

interface IProps {
  children: React.ReactNode;
}

interface IDataProps {
  pathname: string;
  label: string;
  active: boolean;
}

const MuiDataProps: IDataProps[] = [
  { label: 'Início', active: false, pathname: '/' },
  { label: 'Ranking', active: false, pathname: '/ranking' },
  { label: 'Desafios', active: false, pathname: '/desafios' },
  { label: 'Visão por Indicador', active: false, pathname: '/indicadores' },
  { label: 'Análise', active: false, pathname: '/analise' },
  { label: 'O DGE', active: false, pathname: '/dge' },
];

export const NavigatorContextProvider: React.FC<IProps> = ({ children }) => {
  const { pathname } = useRouter();

  const [data, setData] = React.useState<IDataProps[]>(MuiDataProps);

  const handleChangePathname = React.useCallback((path: string) => {

    MuiDataProps.forEach(item => item.active = false);
    MuiDataProps.forEach(item => {
      if (item.pathname === path) {
        item.active = true;
        Router.push(item.pathname);
      }
    });
    setData(MuiDataProps);
  }, []);

  React.useEffect(() => {
    if (pathname) handleChangePathname(pathname);
  }, [pathname]);

  const labels = React.useMemo(() => [...data], [data]);

  const toggleActive = React.useCallback((index: number) => {
    const newArray = [...data];
    newArray.forEach(item => item.active = false);

    newArray[index]?.active
    ? (newArray[index].active = false)
    : (newArray[index].active = true);

    setData(newArray);

    newArray.forEach(item => {
      if (item.active && item.pathname !== '/desafios') {
        Router.push(item.pathname);
      }
    });
  }, [data]);

  const toggleActiveStyles = React.useCallback((index: number) =>
  data[index]?.active ? true : false, [data]);

  const value = { labels, toggleActive, toggleActiveStyles, handleChangePathname } as NavigatorContextProps;
  return <NavigatorContext.Provider value={value}>{children}</NavigatorContext.Provider>;
};

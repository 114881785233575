import _ from 'lodash';
import React from 'react';
import { jsPDF } from 'jspdf';
import htmlCanvas from 'html2canvas';
import { useIndicators } from '@/hooks';
import { useMediaQuery } from '@mui/material';
import { removeItemAll } from '@/utils/removeItemAll';
import { findDuplicates } from '@/utils/findDuplicates';
import { performPositionValue } from '@/utils/performScatterPositionValue';
import { IChart, IChartValues, MuiIndicatorChartProps, MuiPositionProps } from '@/mock/indicators';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

interface IScatterChartContextProps {
  data: IChart[];
  pdfId: string[];
  dataItem: IChart;
  labelTabs: string[];
  axisXDesktop: number[];
  setData: SetState<IChart[]>;
  setDataItem: SetState<IChart>;
  setIndexYear: SetState<number>;
  valuesDataItems: IChartValues[];
  htmlRef: React.RefObject<HTMLDivElement>;
  printHtmlToPdf: (filename: string) => void;
  handleAxisDisplay: (value: number) => number[] | undefined;
}

export const ScatterChartContext = React.createContext({} as IScatterChartContextProps);

interface IProps {
  children: React.ReactNode;
}

export const ScatterChartContextProvider: React.FC<IProps> = ({ children }) => {
  const { indicatorItem } = useIndicators();
  const [data, setData] = React.useState<IChart[]>([]);
  const [dataItem, setDataItem] = React.useState({} as IChart);
  const [labelTabs, setLabelTabs] = React.useState<string[]>([]);
  const [valuesDataItems, setValuesDataItems] = React.useState<IChartValues[]>([]);

  const initialIndexYear = 0;
  const isMobile = useMediaQuery('(max-width:375px)');

  React.useEffect(() => {
    if (indicatorItem?.chart) {
      const arrayCopy = [...indicatorItem?.chart];
      const length = arrayCopy.length;

      const arrayMuiProps = Array.from({ length }, () => MuiIndicatorChartProps);
      const mergeScatterDataWithMuiProps = _.merge(arrayCopy, arrayMuiProps);

      mergeScatterDataWithMuiProps[initialIndexYear].active = true;
      setData(mergeScatterDataWithMuiProps);
    }
  }, [indicatorItem]);

  React.useEffect(() => {
    if (data) {
      setDataItem(data[initialIndexYear]);
      setLabelTabs(data.map(item => `${item?.ano_inicial}-${item?.ano_final}`));
    }
  }, [data]);

  const handleAxisDisplay = React.useCallback((value: number) => {
    if (dataItem?.values) {
      const items = [...dataItem?.values];
      const valuesItem: any = items?.map(item => item.ano_final).concat(...items.map(item => item.ano_inicial));

      const verifyDiffInf = () => {
        const infLimit = Math.round(Math.min(...valuesItem) - (0.05 * Math.min(...valuesItem)));
        const supLimit = Math.round(Math.max(...valuesItem) + (0.05 * Math.max(...valuesItem)));

        const verify = Math.round((supLimit - infLimit) / value);

        if (verify <= 1) {
          return {
            infLimit: Math.round(Math.min(...valuesItem) - (0.5 * Math.min(...valuesItem))),
            supLimit: Math.round(Math.max(...valuesItem) + (0.5 * Math.max(...valuesItem))),
          };
        }
        return  {
          infLimit,
          supLimit,
        };
      };

      const { infLimit, supLimit } = verifyDiffInf();
      const r = Array.from({ length: value }, () => 0).reduce((acc, _ , index) => {
        const constant = Math.round((supLimit - infLimit) / 11);
        if (index !== 0) {
          const currentValue = acc[index -1] + constant;
          acc.push(currentValue);
          return acc;
        }

        if (valuesItem.some((item: any) => item <= 1)) {
          const v = valuesItem.slice(0, value).map((item: number) => {
            if (value > 1) {
              return Number(item.toFixed(2));
            }
            return Number(item.toFixed(1));
          });
          acc.push(...v);
          return acc.sort();
        } else {
          acc.push(infLimit);
        }
        return acc;
      }, [] as any[]);

      if (indicatorItem?.code === 'tx_aneel_fec_25') {
        r.pop();
        r.push(supLimit);
      }

      return r;
    }
  }, [dataItem]);

  React.useEffect(() => {
    if (dataItem?.values) {
      const arrayCopy = [...dataItem?.values];
      const length = arrayCopy?.length;

      const axis = _.takeRight(handleAxisDisplay(11), 11);

      let axisValuesLessOne: any[] = [];
      if (axis && axis.some((item: number) => item < 1)) {
        const { items } = findDuplicates(axis);

        const r = items.map((item, i) => {
          const ref = items[0];

          if (item === ref) {
            return Number(Number(item + 0.08 * i).toFixed(3));
          }

          return item;
        });
        axisValuesLessOne = [...removeItemAll(axis, items[0]), ...r].sort();
      };

      const arrayMuiProps = Array.from({ length }, () => MuiPositionProps);
      const merge = _.merge(arrayCopy, arrayMuiProps).reduce((acc, curr) => {
        const { ano_inicial, ano_final, uf } = curr;
        const axisValues = axisValuesLessOne.length === 0 ? axis : axisValuesLessOne;
        const posicaoFinal = performPositionValue({
          uf,
          labelTab: dataItem.ano_inicial,
          ref: 'final',
          axis: axisValues,
          value: ano_final,
          code: indicatorItem?.code,
        }) as number;

        const posicaoInicial = performPositionValue({
          uf,
          labelTab: dataItem.ano_inicial,
          ref: 'initial',
          value: ano_inicial,
          code: indicatorItem?.code,
          axis: axisValues.sort((a, b) => a - b),
        }) as number;

        acc.push({
          ...curr,
          posicao_final: posicaoFinal,
          posicao_inicial: posicaoInicial,
        });

        return acc;
      }, [] as IChartValues[]);

      const initialMuiPositions = [80.3, 73.2, 65, 57.3, 49.5, 41.8, 34.1, 26.6, 19.2, 11.7, 3.7];
      const finalMuiPositions = [82.5, 75.1, 66.5, 58.6, 51.7, 43.7, 35.4, 28.2, 20.4, 12.5, 9.5];

      let parseMerge: any[] = [];

      if (axis) {
        parseMerge = merge.map(item => {
          const axisValues = axisValuesLessOne.length === 0 ? axis.sort((a,b) => a - b) : axisValuesLessOne.sort((a,b) => a - b);
          const lastItemAxis = axisValues[axisValues.length - 1];
          const firstItemAxis = axisValues[0];

          if (item.ano_inicial > lastItemAxis) {
            const initialPositionValue =  initialMuiPositions[initialMuiPositions.length - 1];
            return {
              ...item,
              posicao_inicial: initialPositionValue,
            };
          }

          if (item.ano_inicial < firstItemAxis) {
            const initialPositionValue = initialMuiPositions[0];
            const finalPositionValue = item.ano_inicial < item.ano_final
            ? initialPositionValue - 2
            : initialPositionValue + 2;
            return {
              ...item,
              posicao_inicial: initialPositionValue,
              posicao_final: finalPositionValue
            };
          }

          if (item.ano_final > lastItemAxis) {
            const finalPositionValue = finalMuiPositions[finalMuiPositions.length - 1];
            return {
              ...item,
              posicao_final: finalPositionValue,
            };
          }

          if (item.ano_final < firstItemAxis) {
            const finalPositionValue = finalMuiPositions[0];
            return {
              ...item,
              posicao_final: finalPositionValue,
            };
          }

          return {
            ...item,
          };
        });
      }

      const orderChartValues = _.orderBy(
        parseMerge,
        ['ano_final'],
        [dataItem.sinal === 1 ? 'desc' : 'asc'
      ]);
      setValuesDataItems(orderChartValues);
    }
  }, [dataItem, handleAxisDisplay, isMobile]);

  const printHtmlToPdf = (filename: string) => {
    const input  = document.getElementById('print_out_container');
    setTimeout(() => {
      htmlCanvas(input as HTMLElement, {logging: true, useCORS: true, width: 1700, height: 1280 }).then(
        canvas => {
          const imgHeight = canvas.height * 480 / canvas.width;
          const imageData = canvas.toDataURL('image/png', 1.0);

          const pdf = new jsPDF({
            orientation: 'p',
            format: [200, 170],
            floatPrecision: 'smart',
          });
          pdf.addImage(imageData, 'PNG', 0, 0, 480, imgHeight);
          pdf.save(filename +'.pdf');
        });
    }, 2000);
  };

  const value = {
    data,
    setData,
    dataItem,
    labelTabs,
    setDataItem,
    printHtmlToPdf,
    valuesDataItems,
    handleAxisDisplay,
  } as IScatterChartContextProps;
  return <ScatterChartContext.Provider value={value}>{children}</ScatterChartContext.Provider>;
};

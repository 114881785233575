interface Return {
  status: boolean;
  message: string;
}

export const emailValidator = (email: string): Return => {
  if (!email) {
    return {
      status: false,
      message: 'E-mail é obrigatório'
    };
  }

  if (email.length > 320) {
    return {
      status: false,
      message: 'Email não pode conter mais de 320 caracteres'
    };
  }

  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
  const invalidChar = emailRegex.test(email);

  if (!invalidChar) {
    return {
      status: false,
      message: 'Email com caracateres inválidos'
    };
  }

  const [local, domain] = email.split('@');
  if (local.length > 64 || local.length === 0) {
    return {
      status: false,
      message: 'Parte local do email com quantidade inválida de caracteres'
    };
  }

  if (domain.length > 255 || domain.length === 0) {
    return {
      status: false,
      message: 'Domínio do email com quantidade inválida de caracteres'
    };
  }

  return {
    status: true,
    message: ''
  };
};

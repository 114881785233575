import { AxiosPromise } from 'axios';
import { axiosUserInstance } from '@/service';

interface IProps {
  password: string;
  token: string;
}

interface IRes {
  message: string;
  status: number;
}

export class ResetPasswordService {
  forgotPass = ({ password, token }: IProps): AxiosPromise<IRes> =>
  axiosUserInstance.post('user/resetpass', { password, token });
}

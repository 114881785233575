import React, { useState } from 'react';


export const MapContext = React.createContext({} as any);

interface IProps {
  children: React.ReactNode;
}


export const MapContextProvider: React.FC<IProps> = ({ children }) => {
  const [tooltipState, setTooltipState] = useState<any>(undefined)

  return (
    <MapContext.Provider value={{
      tooltipState, setTooltipState
    }}>{children}</MapContext.Provider>
  )
}

import styled from 'styled-components';
import Indicator_01 from '../../assets/area/youth/indicator_01.svg';
import Indicator_02 from '../../assets/area/youth/indicator_02.svg';
import Indicator_03 from '../../assets/area/youth/indicator_03.svg';

export const PregnancyIcon = styled(Indicator_02)`
  scale: 80%;
`;
export const YouthSuperiorIcon = styled(Indicator_03)`
  scale: 80%;
`;
export const PercentageYouthIcon = styled(Indicator_01)`
  scale: 80%;
`;

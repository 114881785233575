import styled from 'styled-components';
import Indicator_01 from '../../assets/area/health/indicator_01.svg';
import Indicator_02 from '../../assets/area/health/indicator_02.svg';
import Indicator_03 from '../../assets/area/health/indicator_03.svg';

export const MortalityIcon = styled(Indicator_02)`
  scale: 80%;
`;
export const ExpectationIcon = styled(Indicator_01)`
  scale: 80%;
`;
export const ChronicDiseasesIcon = styled(Indicator_03)`
  scale: 80%;
`;

import React from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Router from 'next/router';
import { ACCESS } from '@/utils/accessVariables';
import { emailValidator } from '@/utils/emailValidator';
import { SubmitHandler, FormHandles } from '@unform/core';
import { rememberMeStore } from '@/service/store/rememberme';
import { LoginService, User, ILogin } from '@/service/endpoints/login';

type SetState<T> =  React.Dispatch<React.SetStateAction<T>>;

export interface IAuthContextProps {
  openLogin: boolean;
  errorMessage: string;
  openRegister: boolean;
  authenticated: boolean;
  handleLogin: () => void;
  openDrawerLogin: boolean;
  tokenDecode: User | null;
  handleLogout: () => void;
  existAccessToken: boolean;
  openForgotPassword: boolean;
  setOpenLogin: SetState<boolean>;
  setAuthenticated: SetState<boolean>;
  setOpenRegister: SetState<boolean>;
  formRef: React.RefObject<FormHandles>;
  setOpenDrawerLogin: SetState<boolean>;
  setOPenForgotPassword: SetState<boolean>;
}

export const AuthContext = React.createContext({} as IAuthContextProps);

interface IProps {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FC<IProps> = ({ children }) => {
  const { login } = new LoginService();
  const formRef = React.useRef<FormHandles>(null);
  const [openLogin, setOpenLogin] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [openRegister, setOpenRegister] = React.useState<boolean>(false);
  const [authenticated, setAuthenticated] = React.useState<boolean>(false);
  const [openDrawerLogin, setOpenDrawerLogin] = React.useState<boolean>(false);
  const [existAccessToken, setExistAccessToken] = React.useState<boolean>(false);
  const [openForgotPassword, setOPenForgotPassword] = React.useState<boolean>(false);
  const [tokenDecode, setTokenDecode] = React.useState(() => {
    const token = Cookies.get('JWT_USER');
    if (token) {
      return JSON.parse(token);
    }
    return null;
  });

  const handleLogin: SubmitHandler<ILogin> = ({ email, password, rememberMe }) => {
    if (!password) {
      setErrorMessage('Senha obrigatória');
      formRef.current?.setFieldError('password', 'error');
      return;
    }

    const { status, message } = emailValidator(email);

    if (!status) {
      setErrorMessage(message);
      formRef.current?.setFieldError('email', 'error');
      return;
    }

    login({ email, password }).then(({ data }) => {
      const { token, status, user } = data;

      if (status === 0) {
        setErrorMessage('confirme a senha ou e-mail');
        return;
      }

      if (!token) {
        setErrorMessage('Token indefinido');
        return;
      }

      setErrorMessage('');
      setOpenLogin(false);
      setTokenDecode(user);
      setAuthenticated(true);
      setOpenDrawerLogin(false);
      Router.push(Router.pathname);
      rememberMeStore.setState({ email, rememberMe });
      Cookies.set('JWT_USER', JSON.stringify(user));
    });
  };

  const handleLogout = () => {
    Cookies.remove('JWT_USER');
    setTokenDecode(null);
    if (authenticated) {
      Router.push(Router.pathname);
    }
    setAuthenticated(!authenticated);
  };

  React.useEffect(() => {
    axios.post(ACCESS.URL, {
      username: ACCESS.USERNAME, password:  ACCESS.PASSWORD
    }).then((res) => {
      if (res.status === 401) {
        Router.push(Router.pathname);
      };

      Cookies.remove(ACCESS.JWT_TOKEN);
      const { access } = res.data as { access: string };
      Cookies.set(ACCESS.JWT_TOKEN, access, { expires: 1, sameSite: 'Lax' });
      setExistAccessToken(true);
    });
  }, []);

  const value = {
    formRef,
    openLogin,
    handleLogin,
    tokenDecode,
    handleLogout,
    setOpenLogin,
    openRegister,
    errorMessage,
    authenticated,
    openDrawerLogin,
    setOpenRegister,
    existAccessToken,
    setAuthenticated,
    setOpenDrawerLogin,
    openForgotPassword,
    setOPenForgotPassword,
  } as IAuthContextProps;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

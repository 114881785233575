import React, { useState } from 'react';


export const MapMobileContext = React.createContext({} as any);

interface IProps {
  children: React.ReactNode;
}

interface Value {
  tooltipState: any
  setTooltipState: any
}
export const MapMobileContextProvider: React.FC<IProps> = ({ children }) => {
  const [tooltipState, setTooltipState] = useState<any>(undefined)
  const value: Value = {
    tooltipState, setTooltipState,
  }
  return (
    <MapMobileContext.Provider value={value}>{children}</MapMobileContext.Provider>
  )
}

import { AxiosPromise } from 'axios';
import { axiosUserInstance } from '@/service';

export interface IRegister {
  name: string;
  uf: string;
  municipio: string;
  cargo: string;
  instituicao: string;
  email: string;
  password: string;
  newsletter: boolean;
  politica_privacidade: boolean;
  atividade_principal: string;
}

interface Res {
  message: string;
  status: number;
}

export class RegisterService {
  createUser = (data: any): AxiosPromise<Res> =>
  axiosUserInstance.post('user/register', data);
}

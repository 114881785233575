import axios from 'axios';
import { ACCESS } from '@/utils/accessVariables';

export const axiosDataInstance = axios.create({
  baseURL: ACCESS.DGE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const axiosUserInstance = axios.create({
  baseURL: ACCESS.API_URL_BASE,
  headers: {
    'Content-Type': 'application/json',
  }
});

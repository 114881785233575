import styled from 'styled-components';
import Indicator_01 from '../../assets/area/social_development/indicator_01.svg';
import Indicator_02 from '../../assets/area/social_development/indicator_02.svg';
import Indicator_03 from '../../assets/area/social_development/indicator_03.svg';

export const HouseholdIcon = styled(Indicator_03)`
  scale: 80%;
`;
export const PercentageIcon = styled(Indicator_01)`
  scale: 80%;
`;
export const GiniCoefficient = styled(Indicator_02)`
  scale: 80%;
`;

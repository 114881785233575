import { persist } from 'zustand/middleware';
import create, { UseBoundStore, StoreApi } from 'zustand';

interface IRemember {
  email: string | null
  forgetMe: () => void
  rememberMe: boolean | null
}

export const rememberMeStore: UseBoundStore<StoreApi<IRemember>> = create(
  persist(
    (set, _) => ({
      email: null,
      rememberMe: null,
      forgetMe: () => {
        set({ rememberMe: null, email: null });
      },
    }),
    {
      name: 'remember',
    },
  ),
);

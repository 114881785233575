import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    //Fonts
    --font-main: -apple-system, BlinkMacSystemFont, Poppins, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

    // Colors
    --title: #161615;
    --green: #20725E;
    --withe: #FFFFFF;
    --dark-gray: #6C6C6C;
    --light-gray: #BEBDBE;
    --green-drawer: #1A8271;
    --hover-button: #20725E80;
    --background-header: #0E2E38;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-main);
  }

  html, body {
    font-family: var(--font-main);
    color: ${props => props.theme.colors.text};
    background: ${props => props.theme.colors.background};
  }


  html {
    width: 100%;
    height: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    width: 100%;
    height: 100%;
  }

  input, button, textarea {
    font-family: var(--font-main)
  }

  input[type='checkbox'] {
    margin: 0 .2rem 0 0;
    accent-color: var(--green);
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    list-style:none;
    padding-left:0;
  }

  button {
    border: none;
    padding: .5rem;
    cursor: pointer;
    margin-top: .5rem;
    text-align: center;
    color: var(--withe);
    background: var(--green);
    font: normal normal normal 12px/19px Poppins;

    &:hover {
      background: var(--hover-button) 0% 0% no-repeat padding-box;
    }
  }

  *:focus {
    outline: none;
  }
`;

import React from 'react';
import { theme } from '../styles/theme';
import { SnackbarProvider } from './Snackbar';
import { ReactQueryProvider } from './ReactQuery';
import { ThemeProvider } from 'styled-components';
import { RankingContextProvider } from '@/context/ranking';
import { StyledEngineProvider } from '@mui/material/styles';
import {
  AuthContextProvider,
  IndicatorContextProvider,
  ScatterChartContextProvider,
  NavigatorContextProvider,
  SinteseContextProvider
} from '@/context';
import { MapContextProvider } from '@/context/map';
import { MapMobileContextProvider } from '@/context/mapMobile';
import { ModalContextProvider } from '@/context/ranking/modalContext';

interface IProps {
  children: React.ReactNode
}

export function MiddlewareProviders({ children }: IProps) {
  return (
    <ReactQueryProvider>
      <SnackbarProvider>
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <ModalContextProvider>
              <AuthContextProvider>
                <SinteseContextProvider>

                  <RankingContextProvider>
                    <NavigatorContextProvider>
                      <MapContextProvider>
                        <MapMobileContextProvider>

                          <IndicatorContextProvider>
                            <ScatterChartContextProvider>
                              {children}
                            </ScatterChartContextProvider>

                          </IndicatorContextProvider>
                        </MapMobileContextProvider>

                      </MapContextProvider>

                    </NavigatorContextProvider>
                  </RankingContextProvider>
                </SinteseContextProvider>

              </AuthContextProvider>
            </ModalContextProvider>
          </StyledEngineProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </ReactQueryProvider>
  );
}

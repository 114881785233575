import styled from 'styled-components';
import YouthSvg from '../assets/area/youth/main.svg';
import HealthSvg from '../assets/area/health/main.svg';
import SafetySvg from '../assets/area/safety/main.svg';
import EducationalSvg from '../assets/area/education/main.svg';
import HumanCapSvg from '../assets/area/human_capital/main.svg';
import InstitutionalSvg from '../assets/area/institutional/main.svg';
import LifeConditionsSvg from '../assets/area/life_conditions/main.svg';
import InfraestructureSvg from '../assets/area/infraestructure/main.svg';
import EconDevelopSvg from '../assets/area/economic_development/main.svg';
import SocialDevelopSvg from '../assets/area/social_development/main.svg';

export const HumanCapitalIcon = styled(HumanCapSvg)`
  width: 50px;
  height: 50px;
`;

export const EconomicDevelopmentIcon = styled(EconDevelopSvg)`
  width: 50px;
  height: 50px;
`;

export const LifeConditionsIcon = styled(LifeConditionsSvg)`
  width: 50px;
  height: 50px;
`;

export const SocialDevelopmentIcon = styled(SocialDevelopSvg)`
  width: 50px;
  height: 50px;
`;

export const EducationalIcon = styled(EducationalSvg)`
  width: 50px;
  height: 50px;
`;

export const InfraestructureIcon = styled(InfraestructureSvg)`
  width: 50px;
  height: 50px;
`;

export const InstitutionalIcon = styled(InstitutionalSvg)`
  width: 50px;
  height: 50px;
`;

export const YouthIcon = styled(YouthSvg)`
  width: 50px;
  height: 50px;
`;

export const HealthIcon = styled(HealthSvg) `
  width: 50px;
  height: 50px;
`;
export const SafetyIcon = styled(SafetySvg) `
  width: 50px;
  height: 50px;
`;

import styled from 'styled-components';
import Indicator_01 from '../../assets/area/human_capital/indicator_01.svg';
import Indicator_02 from '../../assets/area/human_capital/indicator_02.svg';

export const SchoolingIcon = styled(Indicator_01)`
  scale: 100% ;
`;
export const IlliteracyIcon = styled(Indicator_02)`
  scale: 80%;
`;

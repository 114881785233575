import { AxiosPromise } from 'axios';
import { axiosUserInstance } from '@/service';

const mod = 'mail/contact';

export interface IReqContact {
	uf : string;
  nome : string;
	token: string;
	email: string;
	cargo : string;
	emailTo: string;
	mensagem: string;
	municipio : string;
	atividade : string;
	instituicao : string;
}

interface IResContact {
	msg: string;
	erro: string;
  status: string;
}

export class ContactService {
  sendMessage = (data: IReqContact): AxiosPromise<IResContact> =>
    axiosUserInstance.post(mod, data);
}

import { AxiosPromise } from 'axios';
import { axiosUserInstance } from '@/service';

interface IProps {
  email: string;
}

interface IRes {
  token: string;
  status: number;
}

export class ForgotPasswordService {
  forgotPass = ({ email }: IProps): AxiosPromise<IRes> =>
  axiosUserInstance.post('user/forgotpass', { email });
}

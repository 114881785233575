import Cookies from 'js-cookie';
import { useAuth } from '@/hooks';
import { ACCESS } from '@/utils/accessVariables';
import { RankingService } from '@/service';
import React, { useCallback, useState } from 'react';
import { SinteseService } from '@/service/endpoints/sintese';
import { sintese } from '../sintese';
export type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

interface IRankingContextProps {
  RakingData: any
  setResearchArea: SetState<string>
  researchArea: any
  config: any
  indicadores: any;
  rankingSort: any;
  setRankingSort: SetState<any>;
  setFirstPosition: SetState<any>;
  firstPosition: any;
  tooltipState: any;
  setTooltipState: SetState<any>;
  handleOpen: any;
  handleClose: any;
  open: any;
  openMobile: boolean;
  setOpenMobile: SetState<any>;
  Indicadores: any;
}
interface TooltipStateProps {
  indice: number,
  posicao: number,
  variacao_ano: number,
  variacao_decada: number,
  sigla: string
}
export type Indicador = {
  color: string,
  img: string,
  label: string
  label_complete: string
}
interface IndicadoresProps {
  [key: string]: Indicador
}
export const RankingContext = React.createContext({} as IRankingContextProps);

interface IProps {
  children: React.ReactNode
}

export const Indicadores: IndicadoresProps = {
  "capital_humano": {
    color: "#16859E",
    img: "/icon_capital_humano.png",
    label: "Capital Humano",
    label_complete: "Capital Humano"
  },
  "condicoes_de_vida": {
    color: "#48AA8A",
    img: "/icon_condicoes_de_vida.png",
    label: "Condições de vida",
    label_complete: "Condições de vida"

  },
  "desenvolvimento_economico": {
    color: "#FED352",
    img: "/icon_des_economico.png",
    label: "Des. econômico",
    label_complete: "Desenvolvimento econômico"
  },
  "desenvolvimento_social": {
    color: "#E34C70",
    img: "/icon_des_social.png",
    label: "Des. Social",
    label_complete: "Desenvolvimento Social"
  },
  "educacao": {
    color: "#941059",
    img: "/icon_educacao.png",
    label: "Educação",
    label_complete: "Educação"
  },
  "infraestrutura": {
    color: "#D73137",
    img: "/icon_infraestrutura.png",
    label: "Infraestrutura",
    label_complete: "Infraestrutura"
  },
  "institucional": {
    color: "#8CB747",
    img: "/institucional.png",
    label: "Institucional",
    label_complete: "Institucional"
  },
  "juventude": {
    color: "#38A7DF",
    img: "/icon_juventude.png",
    label: "Juventude",
    label_complete: "Juventude"
  },
  "saude": {
    color: "#F78D12",
    img: "/icon_saude.png",
    label: "Saúde",
    label_complete: "Saúde"
  },
  "seguranca": {
    color: "#505251",
    img: "/icon_seguranca.png",
    label: "Segurança",
    label_complete: "Segurança"
  },
  "Geral": {
    color: "#003886",
    img: "null",
    label: "Ranking Geral",
    label_complete: "Geral"
  }
}
export function RankingContextProvider({ children }: IProps) {
  const { getPetalRanking } = new RankingService();
  const { getSintese } = new SinteseService();

  const { existAccessToken } = useAuth();

  const [RakingData, setRakingData] = useState<any>({});
  const [rankingSort, setRankingSort] = useState<any>({});
  const [firstPosition, setFirstPosition] = useState(0)
  const [researchArea, setResearchArea] = React.useState<string>('Geral');


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRanking = useCallback(async () => {
    const accessToken = Cookies.get(ACCESS.JWT_TOKEN) as string;
    const { data } = await getPetalRanking({ accessToken })
    setRakingData(data);
  }, [])

  

  React.useEffect(() => {
    if (existAccessToken) {
      handleRanking();
    }
  }, [existAccessToken]);

  Object.keys(RakingData).forEach((element: any) => {
    const configIndicator = Indicadores[element];
    RakingData[element].config = configIndicator;
  });


  const values = {
    RakingData,
    setResearchArea,
    researchArea,
    rankingSort,
    setRankingSort,
    setFirstPosition,
    firstPosition,
    open,
    handleOpen,
    handleClose,
    Indicadores,

  } as IRankingContextProps;

  return <RankingContext.Provider value={values}>{children}</RankingContext.Provider>;
}

export const findDuplicates = (arr: any[]) => {
  const sorted_arr = arr.sort();
  const array: any[] = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1]) {
      array.push(sorted_arr[i]);
    }
  }

  return {
    items: array.filter((item, i) => item !== array[i])
  };
};

export * from './axios';
export * as Api from '.';
export * from './endpoints/login';
export * from './endpoints/ranking';
export * from './endpoints/contact';
export * from './endpoints/register';
export * from './endpoints/resetpass';
export * from './endpoints/forgotpass';
export * from './endpoints/indicators';

export type ErrorHandler = {
  response: {
    data: {
      message: string;
    };
  }
};

import { AxiosPromise } from 'axios';
import { axiosDataInstance } from '../axios';

export interface IndicatorProps {
  [key: string]: any
}

interface ConfigProps {
  accessToken: string;
}

export class IndicatorsService {
  getIndicators = ({ accessToken }: ConfigProps): AxiosPromise<IndicatorProps> =>
  axiosDataInstance.get('indicators-onepage/', {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
}

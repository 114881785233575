import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export const ACCESS = {
  URL: process.env.NEXT_PUBLIC_URL_TOKEN as string,
  DGE_URL: process.env.NEXT_PUBLIC_URL_DGE as string,
  JWT_USER: process.env.NEXT_PUBLIC_URL_JWT_USER as string,
  USERNAME: process.env.NEXT_PUBLIC_USERNAME_TOKEN as string,
  PASSWORD: process.env.NEXT_PUBLIC_PASSWORD_TOKEN as string,
  API_URL_BASE: process.env.NEXT_PUBLIC_URL_API_BASE as string,
  JWT_TOKEN: process.env.NEXT_PUBLIC_ACCESS_TOKEN_KEY as string,
};

import styled from 'styled-components';
import Indicator_01 from '../../assets/area/infraestructure/indicator_01.svg';
import Indicator_02 from '../../assets/area/infraestructure/indicator_02.svg';
import Indicator_03 from '../../assets/area/infraestructure/indicator_03.svg';
import Indicator_04 from '../../assets/area/infraestructure/indicator_04.svg';
import Indicator_05 from '../../assets/area/infraestructure/indicator_05.svg';
import Indicator_06 from '../../assets/area/infraestructure/indicator_06.svg';

export const HighwaysIcon = styled(Indicator_01)`
  scale: 80%;
`;
export const DurationIcon = styled(Indicator_06)`
  scale: 80%;
`;
export const FrequencyIcon = styled(Indicator_05)`
  scale: 80%;
`;
export const QualityHighwaysIcon = styled(Indicator_02)`
  scale: 80%;
`;
export const PercentagedomicileIcon = styled(Indicator_04)`
  scale: 80%;
`;
export const PercentageResidentsIcon = styled(Indicator_03)`
  scale: 80%;
  `;


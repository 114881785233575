import { AxiosPromise } from 'axios';
import { axiosUserInstance } from '@/service';

export interface Data {
  email: string;
  password: string;
}

export interface ILogin {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface User {
  id: number,
  name: string,
  email: string,
  two_factor_secret: any,
  two_factor_recovery_codes: any,
  two_factor_confirmed_at: any,
  uf: string,
  municipio: string,
  cargo: string,
  instituicao: string,
  newsletter: 1,
  email_verified_at: Date,
  isadmin: number,
  politica_privacidade: number,
  atividade_principal: string
}

export interface LoginReturn {
	token: string;
	status: number;
  message: string;
	user: User;
}

export class LoginService {
  login = (data: Data): AxiosPromise<LoginReturn> =>
  axiosUserInstance.post('user/login', data);
}

import styled from 'styled-components';
import Indicator_01 from '../../assets/area/institutional/indicator_01.svg';
import Indicator_02 from '../../assets/area/institutional/indicator_02.svg';

export const CongestionIcon = styled(Indicator_02)`
  scale: 80%;
`;
export const TransparencyIcon = styled(Indicator_01)`
  scale: 80%;
`;

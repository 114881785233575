import { closeTo } from '../closeTo';

type RefProps = 'final' | 'initial';
type AxisProps = number[] | undefined;

interface IProps {
  uf?: string;
  labelTab?: number;
  code?: string;
  value: number;
  ref: RefProps;
  axis: AxisProps;
  finalMuiPositions?: number[];
  initialMuiPositions?: number[];
}

interface IReturn {
  diffMuiPosition: number;
  refPercentageValue: number;
}

const initialMuiPositions = [81.3, 73.2, 65, 57.3, 49.5, 41.8, 34.1, 26.6, 19.2, 11.7, 3.7];
const finalMuiPositions = [82.5, 75.1, 66.5, 58.6, 51.7, 43.7, 35.4, 28.2, 20.4, 12.5, 9.5];

const buildRefAxisValue = (prev: number, curr: number, value: number, median: number) => {
  if (Math.abs(prev - value) <= median) {
    return prev;
  } else {
    return curr;
  }
};

interface IBuildMuiProps {
  ref: RefProps;
  axis: AxisProps;
  currIndex: number;
  prevIndex: number;
  refAxisIndex: number;
}

const buildMuiProps = ({
  ref,
  axis,
  currIndex,
  prevIndex,
  refAxisIndex,
}: IBuildMuiProps): IReturn => {
  let diffMuiPosition = 0;
  let prevMuiPosition = 0;
  let refPercentageValue = 0;

  if (ref === 'initial') {
    prevMuiPosition = currIndex === 0 ? 0 : initialMuiPositions[prevIndex];
    diffMuiPosition = Math.abs(initialMuiPositions[currIndex] - prevMuiPosition);
    const refIndex = axis?.indexOf(refAxisIndex) as number;
    refPercentageValue = initialMuiPositions[refIndex];
  }

  if (ref === 'final') {
    prevMuiPosition = currIndex === 0 ? 0 : finalMuiPositions[prevIndex];
    diffMuiPosition = Math.abs(finalMuiPositions[currIndex] - prevMuiPosition);
    const refIndex = axis?.indexOf(refAxisIndex) as number;
    refPercentageValue = finalMuiPositions[refIndex];
  }

  return {
    diffMuiPosition,
    refPercentageValue,
  };
};

const replacementPosition = (ref: 'initial' | 'final', index: number, per: any) => {
  if (ref === 'initial') {
    return initialMuiPositions[index];
  }

  if (ref === 'final') {
    return finalMuiPositions[index];
  }

  return per;
};

export const performPositionValue = ({ axis, value, ref, code, uf, labelTab }: IProps) => {
  const currAxisValue = closeTo(axis, Number(value.toFixed(2))) as number;
  const indexCurrAxisValue = axis?.indexOf(currAxisValue) as number;
  const indexPrevAxisValue = indexCurrAxisValue !== 0 ?  indexCurrAxisValue - 1 : indexCurrAxisValue;

  if (axis) {
    const prevAxisValue = indexCurrAxisValue === 0 ? 0 : axis[indexPrevAxisValue];
    const medianIntervalDivider = 2;

    const MedianIntervalValue = Math.abs(currAxisValue - prevAxisValue) / medianIntervalDivider;

    const refAxisValue = buildRefAxisValue(prevAxisValue, currAxisValue, value, MedianIntervalValue);

    const { diffMuiPosition, refPercentageValue } = buildMuiProps({
      ref,
      axis,
      refAxisIndex: refAxisValue,
      currIndex:indexCurrAxisValue,
      prevIndex:indexPrevAxisValue,
    });

    const diffRefIntervalValue = Math.abs(currAxisValue - prevAxisValue);
    const fundamentalDiff = diffMuiPosition / diffRefIntervalValue;

    const diffRefAxisWithValue = Math.abs(Number(refAxisValue.toFixed(2)) - Number(value.toFixed(2)));
    const adicionalRefPercentage = (Number(diffRefAxisWithValue.toFixed(2)) * fundamentalDiff);

    if (code === "tx_pnadc_desocupacao_36") {
      if (uf === 'SC'){
        if (value === 3.4) {
          return diffMuiPosition - 2
        }
      }
    }

    if (code === 'tx_aneel_fec_25') {
      if (value === 4) {
        return diffMuiPosition - value
      }
    }



    if (code === "tx_pnadc_analfabetismo_74" && ref === 'initial') {
      if (value === 2.9) {
        return diffMuiPosition - value
      }
    }

    if (code === "tx_ibge_pib_per_capita_32") {
      if (uf === 'MA') {
        return value === 15.02769 ? diffMuiPosition - 4 : diffMuiPosition - 2;
      }

      if (uf === 'PI') {
        if (value === 16.930419999999998) {
          return diffMuiPosition - 3
        }

        if (value === 17.1847) {
          return diffMuiPosition - 3;

        } else {
          diffMuiPosition - 5;
        }
      }
      if (uf === 'PB') {
        if (value === 17.40213) {
          return refPercentageValue - 4;
        }
      }
    }

    if (code === "tx_inep_pnadc_creche_47") {
      if (uf === 'AP') {
        if (value === 6.7) {
          return diffMuiPosition - value;
        }

        if (value === 6.0) {
          return diffMuiPosition - value + 4.5;
        }
      }

      if (uf === 'PA') {
        if (value === 6.9) {
          return diffMuiPosition - value + 4;
        }
      }
    }

    if (code === 'tx_seeg_emissao_co2_46') {
      if (value < 11) {
        return 83.3 - value;
      }

      if (refAxisValue < value) {
        if (value < 2) {
          return replacementPosition(ref, indexCurrAxisValue, refPercentageValue);
        } else {

          const position = Math.abs(refPercentageValue - adicionalRefPercentage);

          if (ref === 'initial') {
            if (position > initialMuiPositions[0]) {
              const currIndexAxisValue = closeTo(axis, Number(value.toFixed(2))) as number;
              if (value <  axis[currIndexAxisValue - 1] && value > 1) {
                const currentValueAxis = axis[currIndexAxisValue - 1] - value;
                return initialMuiPositions[currIndexAxisValue - 1] + (currentValueAxis * 0.5) + 4;
              }
              return currIndexAxisValue >= 1
              ? initialMuiPositions[currIndexAxisValue - 1] + 4
              : initialMuiPositions[currIndexAxisValue] + 4;
            }
          }
          return position + 4;
        }
      } else {
        return Math.abs(refPercentageValue + adicionalRefPercentage) + 4 ;
      }
    }

    if (code === "tx_pnadc_pct_domicilios_moradia_inadequada_41") {
      if (uf === 'RS') {
        if (value === 7.8) {
          return diffMuiPosition - 1;
        }
      }
    }

    if (code === "tx_pnadc_pct_domicilios_saneamento_45") {
      if (uf === 'RO') {


        if (value === 8.4) {
          return refPercentageValue - 4;
        }

        if (value === 11.2) {
          return 81.3 -4;
        }
      }

      if (uf === 'PI') {

        if (value === 12.8) {
          return refPercentageValue;
        }

        if (value === 13.1) {
          return diffMuiPosition -3;
        }
        return diffMuiPosition - 2;
      }

      if (uf === 'AP') {
        if (value === 13.8) {
          return diffMuiPosition -3;
        }
      }
    }

    if (code === "tx_pnadc_pct_nem_nem_nem_83") {
      const [_, dec] = String(value).split('.');
      const valueDec = (Number(dec) / 10);
      const decimal = isNaN(valueDec) ? 0 : valueDec;
      return refPercentageValue + decimal;
    }

    if (code === "tx_datasus_gravidez_precoce_70") {
      const [_, dec] = String(value).split('.');
      const valueDec = (Number(dec) / 10);
      const decimalPart = isNaN(valueDec) ? 0 : valueDec;

      if (labelTab === 2019) {
        if (currAxisValue >= value) {
          return (refPercentageValue - decimalPart) + 6;
        } else {
          return (refPercentageValue + decimalPart) - 1;
        }
      }

      if (currAxisValue > value) {
        return refPercentageValue + decimalPart;
      } else {
        return refPercentageValue - decimalPart;
      }
    }

    if (code === "tx_pnadc_pct_domicilios_moradia_inadequada_41") {
      if (refAxisValue < value) {
        if (value < 2) {
          return replacementPosition(ref, indexCurrAxisValue, refPercentageValue);
        } else {

          const position = Math.abs(refPercentageValue - adicionalRefPercentage);

          if (ref === 'initial') {
            if (position > initialMuiPositions[0]) {
              const currIndexAxisValue = closeTo(axis, Number(value.toFixed(2))) as number;
              if (value <  axis[currIndexAxisValue - 1] && value > 1) {
                const currentValueAxis = axis[currIndexAxisValue - 1] - value;
                return initialMuiPositions[currIndexAxisValue - 1] + (currentValueAxis * 0.5) + 4;
              }
              return currIndexAxisValue >= 1
              ? initialMuiPositions[currIndexAxisValue - 1] + 4
              : initialMuiPositions[currIndexAxisValue] + 4;
            }
          }
          return position + 4;
        }
      } else {
        return Math.abs(refPercentageValue + adicionalRefPercentage) + 4 ;
      }
    }

    if (code === "tx_pnadc_pct_domicilios_saneamento_45") {
      if (currAxisValue > value) {
        return refPercentageValue + 3;
      } else {
        return refPercentageValue - 3;
      }
    }

    if (code === "tx_pnadc_pct_pop_superior_completo_50") {
      if (refAxisValue < value) {
        if (value < 2) {
          return replacementPosition(ref, indexCurrAxisValue, refPercentageValue);
        } else {

          const position = Math.abs(refPercentageValue - adicionalRefPercentage);

          if (ref === 'initial') {
            if (position > initialMuiPositions[0]) {
              const currIndexAxisValue = closeTo(axis, Number(value.toFixed(2))) as number;
              if (value <  axis[currIndexAxisValue - 1] && value > 1) {
                const currentValueAxis = axis[currIndexAxisValue - 1] - value;
                return initialMuiPositions[currIndexAxisValue - 1] + (currentValueAxis * 0.5) + 4;
              }
              return currIndexAxisValue >= 1
              ? initialMuiPositions[currIndexAxisValue - 1] + 4
              : initialMuiPositions[currIndexAxisValue] + 4;
            }
          }
          return position + 4;
        }
      } else {
        return Math.abs(refPercentageValue + adicionalRefPercentage) + 4 ;
      }
    }

    if (code === 'tx_pnadc_analfabetismo_74') {
      if (refAxisValue < value) {
        if (value < 2) {
          return replacementPosition(ref, indexCurrAxisValue, refPercentageValue);
        } else {

          const position = Math.abs(refPercentageValue - adicionalRefPercentage);

          if (ref === 'initial') {
            if (position > initialMuiPositions[0]) {
              const currIndexAxisValue = closeTo(axis, Number(value.toFixed(2))) as number;
              if (value <  axis[currIndexAxisValue - 1] && value > 1) {
                const currentValueAxis = axis[currIndexAxisValue - 1] - value;
                return initialMuiPositions[currIndexAxisValue - 1] + (currentValueAxis * 0.5) + 2;
              }
              return currIndexAxisValue >= 1
              ? initialMuiPositions[currIndexAxisValue - 1] + 2
              : initialMuiPositions[currIndexAxisValue] + 2;
            }
          }
          return position + 2;
        }
      } else {
        return Math.abs(refPercentageValue + adicionalRefPercentage) + 2 ;
      }
    }

    if (code === 'tx_pnadc_escolaridade_media_88') {
      if (refAxisValue < value) {
        if (value < 2) {
          return replacementPosition(ref, indexCurrAxisValue, refPercentageValue);
        } else {

          const position = Math.abs(refPercentageValue - adicionalRefPercentage);

          if (ref === 'initial') {
            if (position > initialMuiPositions[0]) {
              const currIndexAxisValue = closeTo(axis, Number(value.toFixed(2))) as number;
              if (value <  axis[currIndexAxisValue - 1] && value > 1) {
                const currentValueAxis = axis[currIndexAxisValue - 1] - value;
                return initialMuiPositions[currIndexAxisValue - 1] + (currentValueAxis * 0.5) + 2;
              }
              return currIndexAxisValue >= 1
              ? initialMuiPositions[currIndexAxisValue - 1] + 2
              : initialMuiPositions[currIndexAxisValue] + 2;
            }
          }
          return position + 2;
        }
      } else {
        return Math.abs(refPercentageValue + adicionalRefPercentage) + 2 ;
      }
    }

    if (refAxisValue < value) {
      if (value < 2) {
        return replacementPosition(ref, indexCurrAxisValue, refPercentageValue);
      } else {

        const position = Math.abs(refPercentageValue - adicionalRefPercentage);

        if (ref === 'initial') {
          if (position > initialMuiPositions[0]) {
            const currIndexAxisValue = closeTo(axis, Number(value.toFixed(2))) as number;
            if (value <  axis[currIndexAxisValue - 1] && value > 1) {
              const currentValueAxis = axis[currIndexAxisValue - 1] - value;
              return initialMuiPositions[currIndexAxisValue - 1] + (currentValueAxis * 0.5);
            }
            return currIndexAxisValue >= 1
            ? initialMuiPositions[currIndexAxisValue - 1]
            : initialMuiPositions[currIndexAxisValue];
          }
        }
        return position;
      }
    } else {
      return Math.abs(refPercentageValue + adicionalRefPercentage);
    }
  }
};



import { useAuth } from '@/hooks';
import { RankingService } from '@/service';
import { SinteseService } from '@/service/endpoints/sintese';
import { ACCESS } from '@/utils/accessVariables';
import Cookies from 'js-cookie';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RankingContext } from '../ranking';


export const SinteseContext = React.createContext({} as any);

interface IProps {
  children: React.ReactNode;
}
export const IndicadoresSintese: any = {
  "Capital Humano": {
    color: "#16859E",
    img: "/icon_capital_humano.png",
    label: "Capital Humano",
    label_complete: "Capital Humano"
  },
  "Condições de Vida": {
    color: "#48AA8A",
    img: "/icon_condicoes_de_vida.png",
    label: "Condições de vida",
    label_complete: "Condições de vida"

  },
  "Des. Econômico": {
    color: "#FED352",
    img: "/icon_des_economico.png",
    label: "Des. econômico",
    label_complete: "Desenvolvimento econômico"
  },
  "Des. Social": {
    color: "#E34C70",
    img: "/icon_des_social.png",
    label: "Des. Social",
    label_complete: "Desenvolvimento Social"
  },
  "Educação": {
    color: "#941059",
    img: "/icon_educacao.png",
    label: "Educação",
    label_complete: "Educação"
  },
  "Infraestrutura": {
    color: "#D73137",
    img: "/icon_infraestrutura.png",
    label: "Infraestrutura",
    label_complete: "Infraestrutura"
  },
  "Institucional": {
    color: "#8CB747",
    img: "/institucional.png",
    label: "Institucional",
    label_complete: "Institucional"
  },
  "Juventude": {
    color: "#38A7DF",
    img: "/icon_juventude.png",
    label: "Juventude",
    label_complete: "Juventude"
  },
  "Saúde": {
    color: "#F78D12",
    img: "/icon_saude.png",
    label: "Saúde",
    label_complete: "Saúde"
  },
  "Segurança": {
    color: "#505251",
    img: "/icon_seguranca.png",
    label: "Segurança",
    label_complete: "Segurança"
  },
  "Geral": {
    color: "#003886",
    img: "null",
    label: "Ranking Geral",
    label_complete: "Geral"
  }
}
export type sintese = {
  petal_ranking: string[]
  summary: string[]
  area_summary: string[]
  thermometer: string[]
  year_elevator: string[]
  decade_elevator: string[]
}
interface Value {
  sinteses: sintese;
  setState: any;
  state: any;
  setSinteses: any;
}
export const SinteseContextProvider: React.FC<IProps> = ({ children }) => {
  const { existAccessToken } = useAuth();
  const { getSintese } = new SinteseService();

  const [sinteses, setSinteses] = useState<sintese>([] as unknown as sintese);
  const [state, setState] = useState('SP')
  const [response, setResponse] = useState<any>()

  const value: Value = {
    sinteses,
    setSinteses,
    setState,
    state,
  }

  const handleSintese = useCallback(async () => {
    const accessToken = Cookies.get(ACCESS.JWT_TOKEN) as string;
    const { data } = await getSintese({ accessToken })
    setResponse(data)
  }, [])


  useEffect(() => {
    response && setSinteses(response[state])
  }, [response, state])

  React.useEffect(() => {
    if (existAccessToken) {
      handleSintese();
    }
  }, [existAccessToken]);
  return (
    <SinteseContext.Provider value={value}>{children}</SinteseContext.Provider>
  )
}

import {
  YouthIcon,
  HealthIcon,
  SafetyIcon,
  EducationalIcon,
  HumanCapitalIcon,
  InstitutionalIcon,
  LifeConditionsIcon,
  InfraestructureIcon,
  SocialDevelopmentIcon,
  EconomicDevelopmentIcon,
} from '@/context/indicators/styles/main.icons';
import {
  PreSchool,
  EnrollmentIcon,
  IDEB_I_Icon,
  IDEB_II_Icon,
  HighSchool,
} from '@/context/indicators/styles/education/indicator.icons';
import {
  InadequacyIcon,
  SanitationIcon,
  C02EmissionIcon
} from '@/context/indicators/styles/life_conditions/indicator.icons';
import {
  HighwaysIcon,
  QualityHighwaysIcon,
  PercentageResidentsIcon,
  PercentagedomicileIcon,
  FrequencyIcon,
  DurationIcon,
} from '@/context/indicators/styles/infraestructure/indicator.icons';
import {
  PercentageIcon,
  GiniCoefficient,
  HouseholdIcon
} from '@/context/indicators/styles/social_development/indicator.icons';
import {
  PIBIcon,
  InformalityIcon,
  UnemploymentIcon
} from '@/context/indicators/styles/economic_development/indicator.icons';
import {
  TransparencyIcon,
  CongestionIcon
} from '@/context/indicators/styles/institutional/indicators.icons';
import {
  PercentageYouthIcon,
  PregnancyIcon,
  YouthSuperiorIcon,
} from '@/context/indicators/styles/youth/indicator.icons';
import {
  ExpectationIcon,
  MortalityIcon,
  ChronicDiseasesIcon
} from '@/context/indicators/styles/health/indicator.icons';
import {
  MurdersIcon,
  DeathsIcon,
} from '@/context/indicators/styles/safety/indicator.icons';
import { SchoolingIcon, IlliteracyIcon } from '@/context/indicators/styles/human_capital/indicator.icons';

export interface IChartValues {
  uf: string;
  variacao: string;
  ano_final: number;
  ano_inicial: number;
  posicao_final: number;
  posicao_inicial: number;
}

export interface IChart {
  unity: string;
  sinal: number;
  active: boolean;
  ano_final: number;
  ano_inicial: number;
  values: IChartValues[];
}

interface IMuiColors {
  bg: string;
  main: string;
  hover: string;
}

interface IAno {
  ano_final: number;
  ano_inicial: number;
  pior: { UF: string[], valor: number },
  melhor: { UF: string[], valor: number },
}

interface IDecada {
  ano_final: number;
  ano_inicial: number;
  pior: { UF: string[], valor: number },
  melhor: { UF: string[], valor: number },
}

export interface IVariacoes {
  ano: IAno,
  code: string;
  area: string;
  text: string;
  info: string;
  main_icon: any;
  decada: IDecada,
  chart: IChart[];
  media_br: number;
  destaque: string;
  clicked: boolean;
  sub_title: string;
  colors: IMuiColors;
  title: string;
  complete_name: string;
  indicators_icon: any[];
  pior_uf: { UF: string[], valor: number },
  melhor_uf: { UF: string[], valor: number },
}

export interface IIndicadores {
  variacoes: IVariacoes[]
}

export const MockIndicators: Record<string, any> = {
  capital_humano: {
    variacoes: [
      {
        complete_name: 'Escolaridade média da população com 25 anos ou mais',
        code: 'tx_pnad_escolaridade',
        decada: {
          ano_inicial: 2010,
          ano_final: 2020,
          melhor: { UF: ['RO'], valor: 15 },
          pior: { UF: ['RR'], valor: -12 }
        },
        ano: {
          ano_inicial: 2019,
          ano_final: 2020,
          melhor: { UF: ['RO'], valor: 5 },
          pior: { UF: ['PE', 'RN'], valor: -4 }
        },
        melhor_uf: { UF: ['DF'], valor: 80807.27 },
        pior_uf: { UF: ['MA'], valor: 13089.77 },
        media_br: 31701.86,
        text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        destaque: 'Entre 2015 e 2016, 18 UFs registraram alta taxa de mortalidade infantil',
        info: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua',
        chart: [
          {
            ano_inicial: 2012,
            ano_final: 2020,
            sinal: 1,
            values: [
              {
                ano_inicial: 251.5,
                ano_final: 232.6,
                uf: "RO",
                variacao: "-7.51%"
              },
              {
                ano_inicial: 241.8,
                ano_final: 230.8,
                uf: "AC",
                variacao: "-4.55%"
              },
              {
                ano_inicial: 215.9,
                ano_final: 225.1,
                uf: "AM",
                variacao: "4.26%"
              },
              {
                ano_inicial: 193.6,
                ano_final: 222.7,
                uf: "RR",
                variacao: "15.03%"
              },
              {
                ano_inicial: 219.7,
                ano_final: 243,
                uf: "PA",
                variacao: "10.61%"
              },
              {
                ano_inicial: 208,
                ano_final: 231.8,
                uf: "AP",
                variacao: "11.44%"
              },
              {
                ano_inicial: 252.6,
                ano_final: 241.8,
                uf: "TO",
                variacao: "-4.28%"
              },
              {
                ano_inicial: 261.6,
                ano_final: 276.9,
                uf: "MA",
                variacao: "5.85%"
              },
              {
                ano_inicial: 282.1,
                ano_final: 277,
                uf: "PI",
                variacao: "-1.81%"
              },
              {
                ano_inicial: 256.6,
                ano_final: 255.3,
                uf: "CE",
                variacao: "-0.51%"
              },
              {
                ano_inicial: 267.6,
                ano_final: 270.4,
                uf: "RN",
                variacao: "1.05%"
              },
              {
                ano_inicial: 311.5,
                ano_final: 292,
                uf: "PB",
                variacao: "-6.26%"
              },
              {
                ano_inicial: 335.9,
                ano_final: 320.2,
                uf: "PE",
                variacao: "-4.67%"
              },
              {
                ano_inicial: 318.8,
                ano_final: 314.6,
                uf: "AL",
                variacao: "-1.32%"
              },
              {
                ano_inicial: 275.3,
                ano_final: 253.9,
                uf: "SE",
                variacao: "-7.77%"
              },
              {
                ano_inicial: 259,
                ano_final: 267.2,
                uf: "BA",
                variacao: "3.17%"
              },
              {
                ano_inicial: 289,
                ano_final: 282.4,
                uf: "MG",
                variacao: "-2.28%"
              },
              {
                ano_inicial: 309,
                ano_final: 302.7,
                uf: "ES",
                variacao: "-2.04%"
              },
              {
                ano_inicial: 393.4,
                ano_final: 357.6,
                uf: "RJ",
                variacao: "-9.1%"
              },
              {
                ano_inicial: 325.4,
                ano_final: 314.6,
                uf: "SP",
                variacao: "-3.32%"
              },
              {
                ano_inicial: 334.5,
                ano_final: 310.4,
                uf: "PR",
                variacao: "-7.2%"
              },
              {
                ano_inicial: 306.2,
                ano_final: 283.3,
                uf: "SC",
                variacao: "-7.48%"
              },
              {
                ano_inicial: 368.8,
                ano_final: 332.8,
                uf: "RS",
                variacao: "-9.76%"
              },
              {
                ano_inicial: 323.2,
                ano_final: 309.1,
                uf: "MS",
                variacao: "-4.36%"
              },
              {
                ano_inicial: 269,
                ano_final: 255.9,
                uf: "MT",
                variacao: "-4.87%"
              },
              {
                ano_inicial: 298.2,
                ano_final: 278.9,
                uf: "GO",
                variacao: "-6.47%"
              },
              {
                ano_inicial: 236.7,
                ano_final: 202.1,
                uf: "DF",
                variacao: "-14.62%"
              }
            ]
          },
          {
            ano_inicial: 2012,
            ano_final: 2019,
            sinal: 1,
            values: [
              {
                ano_inicial: 251.5,
                ano_final: 236.2,
                uf: "RO",
                variacao: "-6.08%"
              },
              {
                ano_inicial: 241.8,
                ano_final: 255.8,
                uf: "AC",
                variacao: "5.79%"
              },
              {
                ano_inicial: 215.9,
                ano_final: 226.4,
                uf: "AM",
                variacao: "4.86%"
              },
              {
                ano_inicial: 193.6,
                ano_final: 249.6,
                uf: "RR",
                variacao: "28.93%"
              },
              {
                ano_inicial: 219.7,
                ano_final: 229.7,
                uf: "PA",
                variacao: "4.55%"
              },
              {
                ano_inicial: 208,
                ano_final: 223.8,
                uf: "AP",
                variacao: "7.6%"
              },
              {
                ano_inicial: 252.6,
                ano_final: 254.3,
                uf: "TO",
                variacao: "0.67%"
              },
              {
                ano_inicial: 261.6,
                ano_final: 259.5,
                uf: "MA",
                variacao: "-0.8%"
              },
              {
                ano_inicial: 282.1,
                ano_final: 280,
                uf: "PI",
                variacao: "-0.74%"
              },
              {
                ano_inicial: 256.6,
                ano_final: 265.4,
                uf: "CE",
                variacao: "3.43%"
              },
              {
                ano_inicial: 267.6,
                ano_final: 283.1,
                uf: "RN",
                variacao: "5.79%"
              },
              {
                ano_inicial: 311.5,
                ano_final: 308.1,
                uf: "PB",
                variacao: "-1.09%"
              },
              {
                ano_inicial: 335.9,
                ano_final: 331.3,
                uf: "PE",
                variacao: "-1.37%"
              },
              {
                ano_inicial: 318.8,
                ano_final: 326.4,
                uf: "AL",
                variacao: "2.38%"
              },
              {
                ano_inicial: 275.3,
                ano_final: 248.8,
                uf: "SE",
                variacao: "-9.63%"
              },
              {
                ano_inicial: 259,
                ano_final: 257.7,
                uf: "BA",
                variacao: "-0.5%"
              },
              {
                ano_inicial: 289,
                ano_final: 283.3,
                uf: "MG",
                variacao: "-1.97%"
              },
              {
                ano_inicial: 309,
                ano_final: 304.9,
                uf: "ES",
                variacao: "-1.33%"
              },
              {
                ano_inicial: 393.4,
                ano_final: 371.9,
                uf: "RJ",
                variacao: "-5.47%"
              },
              {
                ano_inicial: 325.4,
                ano_final: 322.1,
                uf: "SP",
                variacao: "-1.01%"
              },
              {
                ano_inicial: 334.5,
                ano_final: 312.4,
                uf: "PR",
                variacao: "-6.61%"
              },
              {
                ano_inicial: 306.2,
                ano_final: 291.5,
                uf: "SC",
                variacao: "-4.8%"
              },
              {
                ano_inicial: 368.8,
                ano_final: 355.9,
                uf: "RS",
                variacao: "-3.5%"
              },
              {
                ano_inicial: 323.2,
                ano_final: 310.2,
                uf: "MS",
                variacao: "-4.02%"
              },
              {
                ano_inicial: 269,
                ano_final: 265.8,
                uf: "MT",
                variacao: "-1.19%"
              },
              {
                ano_inicial: 298.2,
                ano_final: 283.3,
                uf: "GO",
                variacao: "-5.0%"
              },
              {
                ano_inicial: 236.7,
                ano_final: 204.8,
                uf: "DF",
                variacao: "-13.48%"
              }
            ]
          },
          {
            ano_inicial: 2019,
            ano_final: 2020,
            sinal: 1,
            values: [
              {
                ano_inicial: 236.2,
                ano_final: 232.6,
                uf: "RO",
                variacao: "-1.52%"
              },
              {
                ano_inicial: 255.8,
                ano_final: 230.8,
                uf: "AC",
                variacao: "-9.77%"
              },
              {
                ano_inicial: 226.4,
                ano_final: 225.1,
                uf: "AM",
                variacao: "-0.57%"
              },
              {
                ano_inicial: 249.6,
                ano_final: 222.7,
                uf: "RR",
                variacao: "-10.78%"
              },
              {
                ano_inicial: 229.7,
                ano_final: 243,
                uf: "PA",
                variacao: "5.79%"
              },
              {
                ano_inicial: 223.8,
                ano_final: 231.8,
                uf: "AP",
                variacao: "3.57%"
              },
              {
                ano_inicial: 254.3,
                ano_final: 241.8,
                uf: "TO",
                variacao: "-4.92%"
              },
              {
                ano_inicial: 259.5,
                ano_final: 276.9,
                uf: "MA",
                variacao: "6.71%"
              },
              {
                ano_inicial: 280,
                ano_final: 277,
                uf: "PI",
                variacao: "-1.07%"
              },
              {
                ano_inicial: 265.4,
                ano_final: 255.3,
                uf: "CE",
                variacao: "-3.81%"
              },
              {
                ano_inicial: 283.1,
                ano_final: 270.4,
                uf: "RN",
                variacao: "-4.49%"
              },
              {
                ano_inicial: 308.1,
                ano_final: 292,
                uf: "PB",
                variacao: "-5.23%"
              },
              {
                ano_inicial: 331.3,
                ano_final: 320.2,
                uf: "PE",
                variacao: "-3.35%"
              },
              {
                ano_inicial: 326.4,
                ano_final: 314.6,
                uf: "AL",
                variacao: "-3.62%"
              },
              {
                ano_inicial: 248.8,
                ano_final: 253.9,
                uf: "SE",
                variacao: "2.05%"
              },
              {
                ano_inicial: 257.7,
                ano_final: 267.2,
                uf: "BA",
                variacao: "3.69%"
              },
              {
                ano_inicial: 283.3,
                ano_final: 282.4,
                uf: "MG",
                variacao: "-0.32%"
              },
              {
                ano_inicial: 304.9,
                ano_final: 302.7,
                uf: "ES",
                variacao: "-0.72%"
              },
              {
                ano_inicial: 371.9,
                ano_final: 357.6,
                uf: "RJ",
                variacao: "-3.85%"
              },
              {
                ano_inicial: 322.1,
                ano_final: 314.6,
                uf: "SP",
                variacao: "-2.33%"
              },
              {
                ano_inicial: 312.4,
                ano_final: 310.4,
                uf: "PR",
                variacao: "-0.64%"
              },
              {
                ano_inicial: 291.5,
                ano_final: 283.3,
                uf: "SC",
                variacao: "-2.81%"
              },
              {
                ano_inicial: 355.9,
                ano_final: 332.8,
                uf: "RS",
                variacao: "-6.49%"
              },
              {
                ano_inicial: 310.2,
                ano_final: 309.1,
                uf: "MS",
                variacao: "-0.35%"
              },
              {
                ano_inicial: 265.8,
                ano_final: 255.9,
                uf: "MT",
                variacao: "-3.72%"
              },
              {
                ano_inicial: 283.3,
                ano_final: 278.9,
                uf: "GO",
                variacao: "-1.55%"
              },
              {
                ano_inicial: 204.8,
                ano_final: 202.1,
                uf: "DF",
                variacao: "-1.32%"
              }
            ]
          }
        ]
      },
      {
        complete_name: 'Taxa de analfabetismo entre a população com 15 anos ou mais',
        code: 'tx_pnad_analfabetismo',
        decada: {
          ano_inicial: 2010,
          ano_final: 2020,
          melhor: { UF: ['RO'], valor: 15 },
          pior: { UF: ['RR'], valor: -12 }
        },
        ano: {
          ano_inicial: 2019,
          ano_final: 2020,
          melhor: { UF: ['RO'], valor: 5 },
          pior: { UF: ['PE', 'RN'], valor: -4 }
        },
        melhor_uf: { UF: ['DF'], valor: 80807.27 },
        pior_uf: { UF: ['MA'], valor: 13089.77 },
        media_br: 31701.86,
        text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        destaque: 'Entre 2015 e 2016, 18 UFs registraram alta taxa de mortalidade infantil',
        info: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua',
        chart: [
          {
            ano_inicial: 2012,
            ano_final: 2018,
            sinal: -1,
            values: [
              {
                ano_inicial: 27.74,
                ano_final: 26.52,
                uf: "RO",
                variacao: "-4.4%"
              },
              {
                ano_inicial: 19.88,
                ano_final: 17.94,
                uf: "AC",
                variacao: "-9.76%"
              },
              {
                ano_inicial: 28.9,
                ano_final: 25.93,
                uf: "AM",
                variacao: "-10.28%"
              },
              {
                ano_inicial: 25.3,
                ano_final: 23.88,
                uf: "RR",
                variacao: "-5.61%"
              },
              {
                ano_inicial: 21.76,
                ano_final: 21.45,
                uf: "PA",
                variacao: "-1.42%"
              },
              {
                ano_inicial: 24.86,
                ano_final: 20.62,
                uf: "AP",
                variacao: "-17.06%"
              },
              {
                ano_inicial: 24.17,
                ano_final: 24.05,
                uf: "TO",
                variacao: "-0.5%"
              },
              {
                ano_inicial: 13.38,
                ano_final: 13.75,
                uf: "MA",
                variacao: "2.77%"
              },
              {
                ano_inicial: 15.29,
                ano_final: 16.26,
                uf: "PI",
                variacao: "6.34%"
              },
              {
                ano_inicial: 17.83,
                ano_final: 17.65,
                uf: "CE",
                variacao: "-1.01%"
              },
              {
                ano_inicial: 21.34,
                ano_final: 20.23,
                uf: "RN",
                variacao: "-5.2%"
              },
              {
                ano_inicial: 17.07,
                ano_final: 16.91,
                uf: "PB",
                variacao: "-0.94%"
              },
              {
                ano_inicial: 21.59,
                ano_final: 20.61,
                uf: "PE",
                variacao: "-4.54%"
              },
              {
                ano_inicial: 17.36,
                ano_final: 17.41,
                uf: "AL",
                variacao: "0.29%"
              },
              {
                ano_inicial: 22.65,
                ano_final: 18.94,
                uf: "SE",
                variacao: "-16.38%"
              },
              {
                ano_inicial: 21.35,
                ano_final: 19.64,
                uf: "BA",
                variacao: "-8.01%"
              },
              {
                ano_inicial: 34.04,
                ano_final: 30.98,
                uf: "MG",
                variacao: "-8.99%"
              },
              {
                ano_inicial: 40.24,
                ano_final: 35.93,
                uf: "ES",
                variacao: "-10.71%"
              },
              {
                ano_inicial: 50.32,
                ano_final: 45.23,
                uf: "RJ",
                variacao: "-10.12%"
              },
              {
                ano_inicial: 57.41,
                ano_final: 50.67,
                uf: "SP",
                variacao: "-11.74%"
              },
              {
                ano_inicial: 43.29,
                ano_final: 40.72,
                uf: "PR",
                variacao: "-5.94%"
              },
              {
                ano_inicial: 45.64,
                ano_final: 44.02,
                uf: "SC",
                variacao: "-3.55%"
              },
              {
                ano_inicial: 42.4,
                ano_final: 42.14,
                uf: "RS",
                variacao: "-0.61%"
              },
              {
                ano_inicial: 37.68,
                ano_final: 39.14,
                uf: "MS",
                variacao: "3.87%"
              },
              {
                ano_inicial: 37.68,
                ano_final: 39.66,
                uf: "MT",
                variacao: "5.25%"
              },
              {
                ano_inicial: 32.91,
                ano_final: 29.5,
                uf: "GO",
                variacao: "-10.36%"
              },
              {
                ano_inicial: 94.86,
                ano_final: 90.13,
                uf: "DF",
                variacao: "-4.99%"
              }
            ]
          },
          {
            ano_inicial: 2012,
            ano_final: 2019,
            sinal: -1,
            values: [
              {
                ano_inicial: 27.74,
                ano_final: 26.5,
                uf: "RO",
                variacao: "-4.47%"
              },
              {
                ano_inicial: 19.88,
                ano_final: 17.72,
                uf: "AC",
                variacao: "-10.87%"
              },
              {
                ano_inicial: 28.9,
                ano_final: 26.1,
                uf: "AM",
                variacao: "-9.69%"
              },
              {
                ano_inicial: 25.3,
                ano_final: 23.59,
                uf: "RR",
                variacao: "-6.76%"
              },
              {
                ano_inicial: 21.76,
                ano_final: 20.73,
                uf: "PA",
                variacao: "-4.73%"
              },
              {
                ano_inicial: 24.86,
                ano_final: 20.69,
                uf: "AP",
                variacao: "-16.77%"
              },
              {
                ano_inicial: 24.17,
                ano_final: 25.02,
                uf: "TO",
                variacao: "3.52%"
              },
              {
                ano_inicial: 13.38,
                ano_final: 13.76,
                uf: "MA",
                variacao: "2.84%"
              },
              {
                ano_inicial: 15.29,
                ano_final: 16.12,
                uf: "PI",
                variacao: "5.43%"
              },
              {
                ano_inicial: 17.83,
                ano_final: 17.91,
                uf: "CE",
                variacao: "0.45%"
              },
              {
                ano_inicial: 21.34,
                ano_final: 20.34,
                uf: "RN",
                variacao: "-4.69%"
              },
              {
                ano_inicial: 17.07,
                ano_final: 16.92,
                uf: "PB",
                variacao: "-0.88%"
              },
              {
                ano_inicial: 21.59,
                ano_final: 20.7,
                uf: "PE",
                variacao: "-4.12%"
              },
              {
                ano_inicial: 17.36,
                ano_final: 17.67,
                uf: "AL",
                variacao: "1.79%"
              },
              {
                ano_inicial: 22.65,
                ano_final: 19.44,
                uf: "SE",
                variacao: "-14.17%"
              },
              {
                ano_inicial: 21.35,
                ano_final: 19.72,
                uf: "BA",
                variacao: "-7.63%"
              },
              {
                ano_inicial: 34.04,
                ano_final: 30.79,
                uf: "MG",
                variacao: "-9.55%"
              },
              {
                ano_inicial: 40.24,
                ano_final: 34.18,
                uf: "ES",
                variacao: "-15.06%"
              },
              {
                ano_inicial: 50.32,
                ano_final: 45.17,
                uf: "RJ",
                variacao: "-10.23%"
              },
              {
                ano_inicial: 57.41,
                ano_final: 51.14,
                uf: "SP",
                variacao: "-10.92%"
              },
              {
                ano_inicial: 43.29,
                ano_final: 40.79,
                uf: "PR",
                variacao: "-5.78%"
              },
              {
                ano_inicial: 45.64,
                ano_final: 45.12,
                uf: "SC",
                variacao: "-1.14%"
              },
              {
                ano_inicial: 42.4,
                ano_final: 42.41,
                uf: "RS",
                variacao: "0.02%"
              },
              {
                ano_inicial: 37.68,
                ano_final: 38.48,
                uf: "MS",
                variacao: "2.12%"
              },
              {
                ano_inicial: 37.68,
                ano_final: 40.79,
                uf: "MT",
                variacao: "8.25%"
              },
              {
                ano_inicial: 32.91,
                ano_final: 29.73,
                uf: "GO",
                variacao: "-9.66%"
              },
              {
                ano_inicial: 94.86,
                ano_final: 90.74,
                uf: "DF",
                variacao: "-4.34%"
              }
            ]
          },
          {
            ano_inicial: 2018,
            ano_final: 2019,
            sinal: -1,
            values: [
              {
                ano_inicial: 26.52,
                ano_final: 26.5,
                uf: "RO",
                variacao: "-0.08%"
              },
              {
                ano_inicial: 17.94,
                ano_final: 17.72,
                uf: "AC",
                variacao: "-1.23%"
              },
              {
                ano_inicial: 25.93,
                ano_final: 26.1,
                uf: "AM",
                variacao: "0.66%"
              },
              {
                ano_inicial: 23.88,
                ano_final: 23.59,
                uf: "RR",
                variacao: "-1.21%"
              },
              {
                ano_inicial: 21.45,
                ano_final: 20.73,
                uf: "PA",
                variacao: "-3.36%"
              },
              {
                ano_inicial: 20.62,
                ano_final: 20.69,
                uf: "AP",
                variacao: "0.34%"
              },
              {
                ano_inicial: 24.05,
                ano_final: 25.02,
                uf: "TO",
                variacao: "4.03%"
              },
              {
                ano_inicial: 13.75,
                ano_final: 13.76,
                uf: "MA",
                variacao: "0.07%"
              },
              {
                ano_inicial: 16.26,
                ano_final: 16.12,
                uf: "PI",
                variacao: "-0.86%"
              },
              {
                ano_inicial: 17.65,
                ano_final: 17.91,
                uf: "CE",
                variacao: "1.47%"
              },
              {
                ano_inicial: 20.23,
                ano_final: 20.34,
                uf: "RN",
                variacao: "0.54%"
              },
              {
                ano_inicial: 16.91,
                ano_final: 16.92,
                uf: "PB",
                variacao: "0.06%"
              },
              {
                ano_inicial: 20.61,
                ano_final: 20.7,
                uf: "PE",
                variacao: "0.44%"
              },
              {
                ano_inicial: 17.41,
                ano_final: 17.67,
                uf: "AL",
                variacao: "1.49%"
              },
              {
                ano_inicial: 18.94,
                ano_final: 19.44,
                uf: "SE",
                variacao: "2.64%"
              },
              {
                ano_inicial: 19.64,
                ano_final: 19.72,
                uf: "BA",
                variacao: "0.41%"
              },
              {
                ano_inicial: 30.98,
                ano_final: 30.79,
                uf: "MG",
                variacao: "-0.61%"
              },
              {
                ano_inicial: 35.93,
                ano_final: 34.18,
                uf: "ES",
                variacao: "-4.87%"
              },
              {
                ano_inicial: 45.23,
                ano_final: 45.17,
                uf: "RJ",
                variacao: "-0.13%"
              },
              {
                ano_inicial: 50.67,
                ano_final: 51.14,
                uf: "SP",
                variacao: "0.93%"
              },
              {
                ano_inicial: 40.72,
                ano_final: 40.79,
                uf: "PR",
                variacao: "0.17%"
              },
              {
                ano_inicial: 44.02,
                ano_final: 45.12,
                uf: "SC",
                variacao: "2.5%"
              },
              {
                ano_inicial: 42.14,
                ano_final: 42.41,
                uf: "RS",
                variacao: "0.64%"
              },
              {
                ano_inicial: 39.14,
                ano_final: 38.48,
                uf: "MS",
                variacao: "-1.69%"
              },
              {
                ano_inicial: 39.66,
                ano_final: 40.79,
                uf: "MT",
                variacao: "2.85%"
              },
              {
                ano_inicial: 29.5,
                ano_final: 29.73,
                uf: "GO",
                variacao: "0.78%"
              },
              {
                ano_inicial: 90.13,
                ano_final: 90.74,
                uf: "DF",
                variacao: "0.68%"
              }
            ]
          }
        ]
      }
    ]
  },
  desenvolvimento_economico: {
    variacoes: [
      {
        complete_name: 'Desenvolvimento economico A',
        code: 'tx_pnad_dev',
        decada: {
          ano_inicial: 2010,
          ano_final: 2020,
          melhor: { UF: ['RO'], valor: 15 },
          pior: { UF: ['RR'], valor: -12 }
        },
        ano: {
          ano_inicial: 2019,
          ano_final: 2020,
          melhor: { UF: ['RO'], valor: 5 },
          pior: { UF: ['PE', 'RN'], valor: -4 }
        },
        melhor_uf: { UF: ['DF'], valor: 80807.27 },
        pior_uf: { UF: ['MA'], valor: 13089.77 },
        media_br: 31701.86,
        text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        destaque: 'Entre 2015 e 2016, 18 UFs registraram alta taxa de mortalidade infantil',
        info: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua',
      },
      {
        complete_name: 'Desenvolvimento economico B',
        code: 'tx_pnad_an_dev',
        decada: {
          ano_inicial: 2010,
          ano_final: 2020,
          melhor: { UF: ['RO'], valor: 15 },
          pior: { UF: ['RR'], valor: -12 }
        },
        ano: {
          ano_inicial: 2019,
          ano_final: 2020,
          melhor: { UF: ['RO'], valor: 5 },
          pior: { UF: ['PE', 'RN'], valor: -4 }
        },
        melhor_uf: { UF: ['DF'], valor: 80807.27 },
        pior_uf: { UF: ['MA'], valor: 13089.77 },
        media_br: 31701.86,
        text: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur. Quis aute iure reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint obcaecat cupiditat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        destaque: 'Entre 2015 e 2016, 18 UFs registraram alta taxa de mortalidade infantil',
        info: 'Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua',
      }
    ]
  }
};


export const MuiIndicatorProps: Record<string, unknown> = {
  capital_humano: {
    main_icon: <HumanCapitalIcon />,
    area: 'Capital Humano',
    sub_title: 'Visão por indicador',
    colors: {
      bg: '#16859E4D',
      main: '#16859E',
      hover: '#16859E80'
    },
    indicators_icon: [<SchoolingIcon />, <IlliteracyIcon />],
    clicked: false

  },
  desenvolvimento_economico: {
    main_icon: <EconomicDevelopmentIcon />,
    area: 'Desenvolvimento Econômico',
    sub_title: 'Visão por indicador',
    colors: {
      bg: '#FED3524D',
      main: '#FED352',
      hover: '#FED35280'
    },
    indicators_icon: [<PIBIcon />, <UnemploymentIcon />, <InformalityIcon />],
    clicked: false
  },
  condicoes_de_vida: {
    main_icon: <LifeConditionsIcon />,
    area: 'Condições de vida',
    sub_title: 'Visão por indicador',
    colors: {
      bg: '#48AA8A4D',
      main: '#48AA8A',
      hover: '#48AA8A80'
    },
    indicators_icon: [<InadequacyIcon /> ,<SanitationIcon /> , <C02EmissionIcon />],
    clicked: false
  },
  desenvolvimento_social: {
    main_icon: <SocialDevelopmentIcon />,
    area: 'Desenvolvimento social',
    sub_title: 'Visão por indicador',
    colors: {
      bg: '#E34C704D',
      main: '#E34C70',
      hover: '#E34C7080'
    },
    indicators_icon: [<PercentageIcon />, <GiniCoefficient />, <HouseholdIcon />],
    clicked: false
  },
  educacao: {
    main_icon: <EducationalIcon />,
    area: 'Educação',
    sub_title: 'Visão por indicador',
    colors: {
      bg: '#9410594D',
      main: '#941059',
      hover: '#94105980'
    },
    indicators_icon: [<EnrollmentIcon />, <PreSchool />, <IDEB_I_Icon />, <IDEB_II_Icon />, <HighSchool />],
    clicked: false
  },
  infraestrutura: {
    main_icon: <InfraestructureIcon />,
    area: 'Infraestrutura',
    sub_title: 'Visão por indicador',
    colors: {
      bg: '#D731374D',
      main: '#D73137',
      hover: '#D7313780'
    },
    indicators_icon: [

      <QualityHighwaysIcon />,
      <PercentageResidentsIcon />,
      <PercentagedomicileIcon />,
      <FrequencyIcon />,
      <DurationIcon />,
      <HighwaysIcon />,
    ],
    clicked: false
  },
  institucional: {
    main_icon: <InstitutionalIcon />,
    area: 'Institucional',
    sub_title: 'Visão por indicador',
    colors: {
      bg: '#8CB7474D',
      main: '#8CB747',
      hover: '#8CB74780'
    },
    indicators_icon: [<TransparencyIcon />, <CongestionIcon />],
    clicked: false
  },
  juventude: {
    main_icon: <YouthIcon />,
    area: 'Juventude',
    sub_title: 'Visão por indicador',
    colors: {
      bg: '#38A7DF4D',
      main: '#38A7DF',
      hover: '#38A7DF80'
    },
    indicators_icon: [<PercentageYouthIcon />,  <PregnancyIcon />, <YouthSuperiorIcon />],
    clicked: false
  },
  saude: {
    main_icon: <HealthIcon />,
    area: 'Saúde',
    sub_title: 'Visão por indicador',
    colors: {
      bg: '#F78D124D',
      main: '#F78D12',
      hover: '#F78D1280'
    },
    indicators_icon: [<ExpectationIcon />, <MortalityIcon />, <ChronicDiseasesIcon />],
    clicked: false
  },
  seguranca: {
    main_icon: <SafetyIcon />,
    area: 'Segurança',
    sub_title: 'Visão por indicador',
    colors: {
      bg: '#5052514D',
      main: '#505251',
      hover: '#50525180'
    },
    indicators_icon: [<MurdersIcon />, <DeathsIcon />],
    clicked: false
  }
};

export const MuiPositionProps = {
  posicao_final: 0,
  posicao_inicial: 0,
};

export const MuiIndicatorChartProps = {
  active: false,
};

import React, { useState } from 'react';


export const ModalContext = React.createContext({} as any);

interface IProps {
  children: React.ReactNode;
}

interface Value {
  open: any;
  setOpen: any;
}
export const ModalContextProvider: React.FC<IProps> = ({ children }) => {
  const [open, setOpen] = useState(false)

  const value: Value = {
    open, setOpen
  }
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  )
}

import { AxiosPromise } from 'axios';
import { axiosDataInstance } from '../axios';

export interface SinteseServiceProps {
  [key: string]: any
}
interface ConfigProps {
  accessToken: string;
}
export class SinteseService {
  getSintese = ({ accessToken }: ConfigProps): AxiosPromise<SinteseServiceProps> =>
  axiosDataInstance.get('/states-summary/', {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
}

import styled from 'styled-components';
import Indicator_01 from '../../assets/area/education/indicator_01.svg';
import Indicator_02 from '../../assets/area/education/indicator_02.svg';
import Indicator_03 from '../../assets/area/education/indicator_03.svg';
import Indicator_04 from '../../assets/area/education/indicator_04.svg';
import Indicator_05 from '../../assets/area/education/indicator_05.svg';

export const PreSchool = styled(Indicator_02)`
  scale: 80%;
`;
export const HighSchool = styled(Indicator_05)`
  scale: 80%;
`;
export const IDEB_I_Icon = styled(Indicator_03)`
  scale: 80%;
`;
export const IDEB_II_Icon = styled(Indicator_04)`
  scale: 80%;
`;
export const EnrollmentIcon = styled(Indicator_01)`
  scale: 80%;
`;

